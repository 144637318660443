import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.es';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.en';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionRPA() {
	return (
		<LayoutEN>
			<Seo title='Automatización inteligente' />
			<section id='introSolution'>
				<div className='solutionBg solution-orchestration'>
					<div id='introSolution-text'>
						<h1>Service orchestration</h1>
						<p>The perfect marriage between technology and business</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_CICDCA.png'>
					<h2>CI/CD/CA (Continuous Automation)</h2>
					<p>
						All the good things about the pipelines you already know, but{' '}
						<strong>
							seamlessly integrated into an interdepartmental and
							interorganizational process
						</strong>
						.
					</p>

					<p>
						In other words, a website becomes public when the CEO clicks "yes"
						or a server goes into maintenance mode when the client confirms.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='RPA retrying'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_visible-processes.png'>
					<h2>Make your processes visible</h2>
					<p>
						When there are 8 teams working in parallel, you see pipelines and
						deployments like rain.
					</p>
					<p>
						With KuFlow you will be able to give{' '}
						<strong>meaning and context</strong> to each of them.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processlist_01_EN.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Process List'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_world-class-rpa.png'>
					<h2>Resilience as a Service</h2>
					<p>
						KuFlow is designed to be <strong>resilient</strong>. This means:
					</p>
					<ol>
						<li>
							It handles <strong>retries</strong>.
						</li>
						<li>
							It handles connection <strong>timeouts</strong>.
						</li>
						<li>
							It handles <strong>asynchronous processes</strong> perfectly.
						</li>
						<li>
							<strong>It stores request states</strong> so nothing goes under
							the radar.
						</li>
					</ol>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_synergies.png'>
					<h2>Buzzword fan? Get ready</h2>
					<p>
						If you're trying to convince a muggle dev of what KuFlow does, we
						have the right words:
					</p>
					<quote>
						KuFlow is a{' '}
						<strong>
							low-maintenance, resilient orchestration workflow engine as a
							service
						</strong>{' '}
						for <strong>automated business processes</strong> that allows a{' '}
						<strong>top-down 360º vision</strong> with{' '}
						<strong>actionable insights</strong>, perfect for your
						<strong>business intelligence dashboard</strong>.
					</quote>
					<p>
						Too bad we didn't also have some blockchain or IoT, but we didn't
						want to get too crazy either.
					</p>
				</Benefit>
			</section>
			<SolutionsBlockEN />
			<BottomCTA />
		</LayoutEN>
	);
}
